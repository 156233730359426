function answerBlockUi(type, results = [], source, fromCategory = false) {
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
  const redirection = GlobalSite.checkDependency('GlobalSite.getFaqRedirection');
  const Log = logFactory('answerBlockHandler.js');
  const $root = $('.inbenta-faq-list');
  const $title = $('.inbenta-faq-list-title');
  const $listItems = $('.inbenta-faq-list-items');
  const $showMoreBtn = $('.inbenta-more-results-btn');
  const $emptyResult = $('.inbenta-not-found-content');
  const $emptyCategoryResult = $('.inbenta-not-found-category-content');
  const showMoreMessageInit = $showMoreBtn.find('button').text();
  const nbItem = results.length;

  const config = {
    search: { text: 'Notre réponse' },
    popular: { text: 'Les 5 questions les plus consultées' },
  };

  let $listItem;

  function template() {
    return results
      .map((suggestion, index) => {
        return ` <li class="inbenta-faq-list-item ${
          index > 4 ? 'inbenta-faq-list-item--hidden' : ''
        }">
            <a href="${redirection?.answer}${
              suggestion.slug
            }" data-source="${source}" data-tracking-type="click" data-tracking-value="${
          suggestion?.tracking?.clickCode || ''
        }" class="inbenta-faq-list-link" aria-label="${suggestion.title}">${suggestion.title}</a>
            </li>`;
      })
      .join('');
  }

  function showEmptyBlock() {
    if (fromCategory) {
      !$emptyResult.hasClass('hide') && $emptyResult.addClass('hide');
      $emptyCategoryResult.removeClass('hide');
    } else {
      !$emptyCategoryResult.hasClass('hide') && $emptyCategoryResult.addClass('hide');
      $emptyResult.removeClass('hide');
    }
  }

  function hideEmptyBlock() {
    if (fromCategory) {
      !$emptyResult.hasClass('hide') && $emptyResult.addClass('hide');
      $emptyCategoryResult.addClass('hide');
    } else {
      !$emptyCategoryResult.hasClass('hide') && $emptyCategoryResult.addClass('hide');
      $emptyResult.addClass('hide');
    }
  }

  (function render() {
    $showMoreBtn.show();
    $title.text(config[type]?.text || type);
    $listItems.html(template());
    $listItem = $('.inbenta-faq-list-item');
    if (nbItem === 0) {
      showEmptyBlock();
      $showMoreBtn.hide();
    } else if (nbItem <= 5) {
      $showMoreBtn.hide();
      hideEmptyBlock();
    } else {
      hideEmptyBlock();
    }
    $root.removeClass('hide');
  })();

  $showMoreBtn.on('click focus', () => {
    $listItem.slice(5, nbItem).toggleClass('inbenta-faq-list-item--hidden');

    if ($listItem.is('.inbenta-faq-list-item--hidden')) {
      $showMoreBtn.find('button').text(showMoreMessageInit);
    } else {
      $showMoreBtn.find('button').text('Afficher moins de résultats');
    }
  });

  $listItem.on('click', (e) => {
    if (inbentaApi.isTracking()) {
      e.preventDefault();
      const $this = $(e.target);
      const source = $this.data('source');
      const trackingType = $this.data('trackingType');
      const trackingValue = $this.data('trackingValue');
      inbentaApi.sendTrackingEvents({ type: trackingType, data: { code: trackingValue } }, source);
      window.location.replace($this.attr('href'));
      Log.log({ trackingType, trackingValue, source });
    }
  });
}

(function answerBlockHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    answerBlockUi,
  });
})();
